var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [!_vm.partReference ? _c('AppLoader', {
    attrs: {
      "type": "linear",
      "color": "hardware_lists"
    }
  }) : _vm._e(), _vm.transformedPartReference ? _c('div', [_c('div', {
    staticClass: "c-grid mb-2"
  }, [_c('div', {
    staticClass: "col-sixth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Drawing number"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedPartReference.drawingNumber) + " ")])])], 1), _c('div', {
    staticClass: "col-sixth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "AsBuilt"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedPartReference._asBuiltNumber) + " ")])])], 1), _c('div', {
    staticClass: "col-sixth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Side"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedPartReference._side) + " ")])])], 1), _c('div', {
    staticClass: "col-sixth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Size"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedPartReference._size) + " ")])])], 1), _c('div', {
    staticClass: "col-half"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Description"
    }
  }, [_vm._v(_vm._s(_vm.transformedPartReference._description))])], 1), _c('div', {
    staticClass: "col-sixth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Serial"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedPartReference._serialNumber) + " ")])])], 1), _c('div', {
    staticClass: "col-sixth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Lot"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedPartReference._lotNumber) + " ")])])], 1), _c('div', {
    staticClass: "col-sixth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Class"
    }
  }, [_vm.transformedPartReference._itemClasses.length ? _c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedPartReference._itemClasses.join(', ')) + " ")]) : _c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(_vm._s(_vm.DEFAULT_DASH))])])], 1), _c('div', {
    staticClass: "col-sixth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "CEI number"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedPartReference._contractEndingItemNumber) + " ")])])], 1), _c('div', {
    staticClass: "col-sixth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Calbration number"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedPartReference._calibrationNumber) + " ")])])], 1)]), _c('div', {
    staticClass: "c-grid"
  }, [_c('div', {
    staticClass: "col-sixth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Birth"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedPartReference._birthDate) + " ")]), _vm.transformedPartReference.birthDate ? _c('small', {
    staticClass: "d-block"
  }, [_vm._v(" " + _vm._s(_vm.transformedPartReference._birthDateDistanceInWordsFromToday) + " ")]) : _vm._e()])], 1), _c('div', {
    staticClass: "col-sixth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Last calibration"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedPartReference._lastCalibrationDate) + " ")]), _vm.transformedPartReference.lastCalibrationDate ? _c('small', {
    staticClass: "d-block"
  }, [_vm._v(" " + _vm._s(_vm.transformedPartReference._lastCalibrationDateDistanceInWordsFromToday) + " ")]) : _vm._e()])], 1), _c('div', {
    staticClass: "col-sixth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Usage life expiry"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedPartReference._usageLifeExpirationDate) + " ")]), _vm.transformedPartReference.usageLifeExpirationDate ? _c('small', {
    staticClass: "d-block"
  }, [_vm._v(" " + _vm._s(_vm.transformedPartReference._usageLifeExpirationDateDistanceInWordsFromToday) + " ")]) : _vm._e()])], 1), _c('div', {
    staticClass: "col-sixth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Shelf life expiry"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedPartReference._shelfLifeExpirationDate) + " ")]), _vm.transformedPartReference.shelfLifeExpirationDate ? _c('small', {
    staticClass: "d-block"
  }, [_vm._v(" " + _vm._s(_vm.transformedPartReference._shelfLifeExpirationDateDistanceInWordsFromToday) + " ")]) : _vm._e()])], 1), _c('div', {
    staticClass: "col-sixth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Computed need"
    }
  }, [_c('span', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedPartReference._computedNeedDate) + " ")]), _vm.transformedPartReference.computedNeedDate ? _c('small', {
    staticClass: "d-block"
  }, [_vm._v(" " + _vm._s(_vm.transformedPartReference._computedNeedDateDistanceInWordsFromToday) + " ")]) : _vm._e()])], 1)]), !_vm.transformedPartReference.itemInstanceId ? _c('div', {
    staticClass: "c-grid"
  }, [_c('div', {
    staticClass: "col-fourth"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Location"
    }
  }, [_vm._v(_vm._s(_vm.transformedPartReference._location))])], 1)]) : _vm._e(), _c('div', {
    staticClass: "c-grid"
  }, [_c('div', {
    staticClass: "col-third"
  }, [_c('AppBlockquote', {
    attrs: {
      "title": "Notes"
    }
  }, [_c('AppMarkdownDisplay', {
    attrs: {
      "markdown": _vm.transformedPartReference.notes
    }
  })], 1)], 1)]), !_vm.isEmpty(_vm.transformedPartReference.attributes) ? _c('div', {
    staticClass: "c-grid mb-2"
  }, [_c('div', {
    staticClass: "col-full"
  }, [_c('ButtonGeneric', {
    attrs: {
      "small": "",
      "icon": "fa-solid fa-display-code"
    },
    on: {
      "click": function ($event) {
        _vm.viewingRawData = !_vm.viewingRawData;
      }
    }
  }, [!_vm.viewingRawData ? _c('span', [_vm._v("View")]) : _c('span', [_vm._v("Hide")]), _vm._v("  raw import data ")])], 1), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.viewingRawData ? _c('div', {
    staticClass: "col-full mt-2"
  }, [_c('vue-json-pretty', {
    attrs: {
      "dark": "",
      "data": _vm.transformedPartReference.attributes
    }
  })], 1) : _vm._e()])], 1) : _vm._e()]) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };